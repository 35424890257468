import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  heading: {
    paddingRight: "4rem"
  },
  link: {
    marginRight: "2rem",
    color: "#d45c54",
    fontWeight: "700"
  },
  linkText: {
    color: "#656363"
  },
  p: {
    paddingRight: "4rem",
    paddingBottom: "1rem",
    borderBottom: "2px solid #ade8e8"
  },
  right: {paddingLeft: "2rem"}
}))
const ApplicationCard = () => {
  const classes = useStyles()
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item sm={12} md={4}>
        <div className={classes.right}>
          <h2 className={classes.heading}>Web Applications</h2>
          <p className={classes.p}>Links to hosted software applications.</p>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <Card>
          <CardContent>
            <h4>Web Applications</h4>
            <p className={classes.linkText}>
              <a
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                href="https://outlook.office.com"
              >
                Outlook Email
              </a>
              Web Email Login Portal
            </p>
            <p className={classes.linkText}>
              <a
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                href="https://clarkston.myshelby.org"
              >
                Shelby Arena
              </a>
              Church Database | Membership &amp; Registrations, Check In
            </p>
            <p className={classes.linkText}>
              <a
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                href="https://login.planningcenteronline.com/login/new"
              >
                Planning Center
              </a>
              Worship planning software
            </p>
            <p className={classes.linkText}>
              <a
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                href="https://clarkstonumc.mhsoftware.com/"
              >
                Building Calendar
              </a>
              MH Software Building and Resource Scheduling Calendar
            </p>
            <p className={classes.linkText}>
              <a
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                href="https://heuristic-noyce-2b2b34.netlify.com"
              >
                eNews Email Reconnect Tool
              </a>
              ReSubscribe Tool for eNews/prayer emails
            </p>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ApplicationCard
