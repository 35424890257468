import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  heading: {
    paddingRight: "4rem"
  },
  link: {
    marginRight: "2rem",
    color: "#d45c54",
    fontWeight: "700"
  },
  linkText: {
    color: "#656363"
  },
  p: {
    paddingRight: "4rem",
    paddingBottom: "1rem",
    borderBottom: "2px solid #ade8e8"
  },
  right: {paddingLeft: "2rem"}
}))
const FileCard = () => {
  const classes = useStyles()
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item sm={12} md={4}>
        <div className={classes.right}>
          <h2 className={classes.heading}>General Information</h2>
          <p className={classes.p}>
            How to and other quick reference documents.
          </p>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <Card>
          <CardContent>
            <h4>General Information</h4>
            <p className={classes.linkText}>
              <a
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                href={process.env.PUBLIC_URL + "/bldgUse.pdf"}
              >
                Building Use Policy
              </a>
              Complete Policy plus signature forms
            </p>
            <p className={classes.linkText}>
              <a
                className={classes.link}
                rel="noopener noreferrer"
                target="_blank"
                href={
                  process.env.PUBLIC_URL + "/sharedFileInstructionsApple.pdf"
                }
              >
                Connect to Shared Files form OSX
              </a>
              Steps to connect to the Shared Files server from Apple computer.
            </p>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default FileCard
