import React from "react"
import Container from "@material-ui/core/Container"

import ApplicationCard from "./ApplicationCard"
import PhoneCard from "./PhoneCard"
import FileCard from "./FileCard"

const Dash = () => {
  return (
    <Container>
      <h1>Dashboard</h1>
      <ApplicationCard />
      <PhoneCard />
      <FileCard />
    </Container>
  )
}

export default Dash
