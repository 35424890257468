import React from "react"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import DescriptionIcon from "@material-ui/icons/Description"
import Button from "@material-ui/core/Button"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    backgroundColor: "#d45c54",
    "&:hover": {
      backgroundColor: "#cc3538"
    }
  },
  link: {width: "100%", textDecoration: "none"}
}))

const ArenaGridItem = props => {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card style={{textAlign: "center"}}>
        <CardContent>
          <h3>{props.title}</h3>
          <p>{props.note}</p>
        </CardContent>
        <CardActions>
          <a
            className={classes.link}
            mx="auto"
            rel="noopener noreferrer"
            target="_blank"
            href={process.env.PUBLIC_URL + props.file}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<DescriptionIcon />}
            >
              Document
            </Button>
          </a>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default ArenaGridItem
