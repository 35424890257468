import React from "react"
import {Link} from "react-router-dom"
import {connect} from "react-redux"
import {logoutUser} from "../actions"

import NavDrawer from "./Drawer"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import {withStyles} from "@material-ui/styles"
import Hidden from "@material-ui/core/Hidden"

const styles = () => ({
  root: {
    flexGrow: 1
  },
  bar: {
    backgroundColor: "#242121"
  },
  menuButton: {
    marginRight: "16px"
  },
  title: {
    flexGrow: 1,
    color: "#ffffff"
  },
  link: {
    color: "#ffffff",
    fontWeight: 400,
    "&:hover": {
      color: "#ade8e8"
    }
  },
  drawer: {}
})
class ButtonAppBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      drawerOpened: false
    }
  }
  toggleDrawer = booleanValue => () => {
    this.setState({
      drawerOpened: booleanValue
    })
  }

  handleLogout = () => {
    const {dispatch} = this.props
    dispatch(logoutUser())
  }

  render() {
    const {classes, isAuthenticated} = this.props

    if (isAuthenticated) {
      return (
        <div className={classes.root}>
          <AppBar position="static" className={classes.bar}>
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                Staff Portal
              </Typography>
              <Hidden smDown>
                <Button
                  className={classes.link}
                  component={Link}
                  to="/dash"
                  color="inherit"
                >
                  Dashboard
                </Button>
                <Button
                  className={classes.link}
                  component={Link}
                  to="/arena"
                  color="inherit"
                >
                  Arena Help
                </Button>
                <Button
                  className={classes.link}
                  component={Link}
                  to="/sunday"
                  color="inherit"
                >
                  Sunday Technology
                </Button>
                <button variant="outlined" onClick={this.handleLogout}>
                  Logout
                </button>
              </Hidden>

              <Hidden mdUp>
                <IconButton
                  onClick={this.toggleDrawer(true)}
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                >
                  <MenuIcon />
                </IconButton>
              </Hidden>
            </Toolbar>
          </AppBar>
          <NavDrawer
            className={classes.drawer}
            drawerOpened={this.state.drawerOpened}
            toggleDrawer={this.toggleDrawer}
          />
        </div>
      )
    } else {
    }

    //////////////////
    // REAL CODE BELOW
    ////////////////////

    return (
      <div className={classes.root}>
        <AppBar position="static" className={classes.bar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Staff Portal
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
    isAuthenticated: state.auth.isAuthenticated
  }
}
export default withStyles(styles)(connect(mapStateToProps)(ButtonAppBar))
