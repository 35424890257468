import React from "react"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import ArenaGridItem from "./ArenaGridItem"

const docs = [
  {
    file: "p1.pdf",
    title: "Mailing Labels",
    note: "How to print mailing labels"
  },
  {
    file: "p2.pdf",
    title: "Register Person",
    note: "How to manually register a person for an event"
  },
  {
    file: "p3.pdf",
    title: "Visitor Attendance",
    note: "How to run or print a visitor attendance report"
  },
  {
    file: "p4.pdf",
    title: "Program Attendance",
    note: "How to run or print an attendance report for a program area"
  },
  {
    file: "p5.pdf",
    title: "Check In Monitor",
    note: "How to view real time check in information"
  },
  {
    file: "p6.pdf",
    title: "Mark Inactive",
    note: "How to mark a person inactive or remove from a tag"
  },
  {
    file: "p7.pdf",
    title: "Create Event",
    note: "How to create an event with or without registration"
  }
]

const Arena = () => {
  return (
    <Container>
      <h1>Arena Frequently Asked Questions</h1>
      <Grid container spacing={3} alignItems="center">
        {docs.map(({file, title, note}) => (
          <ArenaGridItem file={file} title={title} note={note} />
        ))}
      </Grid>
    </Container>
  )
}

export default Arena
