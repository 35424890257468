import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import CheckListItem from "./CheckListItem"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}))

export default function CheckList() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h3>Building</h3>
      <CheckListItem
        title="Set Digital Signage to Sanctuary Feed"
        note="Access building signage XPanel (http://10.0.0.3 using password 4753) and drag blue “Sanctuary” house icon to all 7 screen locations. Note: The  web control app can be accessed from any computer in the building connected to the main network, however the browser you use must be capable of running flash."
      />
      <h3>Sanctuary</h3>
      <CheckListItem
        title="Turn on Chancel and Sanctuary Monitors"
        note="Turn on two video screens in Chancel area and the one at the back of the Sanctuary.  Use the black LG remote labelled “Chancel” which should be on top of the organ console.  "
      />
      <CheckListItem
        title="Check Wireless microphones"
        note="Locate the four wireless handheld microphones (mics) that should be on the sides of the Chancel area.  Turn each one on and check the battery indicator on the meter.  If you see 3 or 4 bars, you can turn the mic off.  If you see less than 3 bars, replace the batteries, then turn the mic off."
      />
      <CheckListItem
        title="Check Floor Microphones"
        note="There a 4 available floor microphones (condenser mics). If chancel choir is singing 2 should be behind alter. If a childrens or youth choir, or ensemble group needs microphones, 2 should be placed in front of the chancel steps."
      />
      <h3>Balcony</h3>
      <CheckListItem
        title="Get Hard Drive for Service Recording"
        note="Get a blank card for recording from Steve’s desk, print out 3 copies of Worship script from Planning Center, take to balcony "
      />
      <CheckListItem
        title="Power On Sound System"
        note="Turn on “Video System Power” (top right of the short rack to the right of the A/V desk) Turn on “Sound Rack Power” (white switch below and to the left of the previous switch) Turn on “Left Display Power” and “Right Display Power” for the two large screens in the sanctuary (white buttons marked “ON” to the left of the previous switch).  You should see color bars on the big screens within 10 seconds. Use the Samsung remote on the A/V desk to power on the monitor behind the video switcher and the large overhead Samsung monitor for the balcony occupants. Turn on the Shogun Flame recorder (to the right of the audio mixer) by pressing the power button on the right side.  Slide the black memory card into the slot on the back. Turn on the power switch on the back of the Allen & Heath audio mixer (above where the power cord is attached: 1=on, 0=off).  It’s normal for the console to take a full minute to initialize and connect to the MixRack before the two main screens show their normal views.  On the Bank 1 green buttons in the center of the surface, select “C” and on the Bank 2 green buttons on the right edge, select “E”. Power on the livestream encoder (small black box to the left of the video switcher)."
      />
      <h3>Pre-Service</h3>
      <CheckListItem
        title="Check ProPresenter is Ready"
        note="Wake up the ProPresenter iMac by jiggling the wireless mouse or pressing any key on the keyboard.  (If you need to login to the “Projector” account, use the password “balcony”.)  Check to see if the announcement and worship slides for this Sunday have already been imported.  If not, click on the finder icon in hte lower left corner of the screen. Then on the top menu bar, click GO, then click Connect to Server, then click on the smb://cumc-file-01 server. This will log finder into the shared files. In the shared files, scroll down to the worship folder, and choose the current years folder. Then choose either the worship slide folder or announcement slide folder. Drag the slides for the current date over from the shared files list onto the desktop of the balcony computer. Then in propresenter, choose file, import powerpoint, then choose the files you just added to the desktop."
      />
      <CheckListItem
        title="Run announcement Slides"
        note="Load the announcement slides sequence, set the timer duration for 10 seconds and click the first slide to start the loop.  Ensure the green button in the upper right under the white tape labelled “SCREENS” is lit up.  Then select the “Slides” source button on the program bus of the video switcher.  Make sure you can see the output of the slides from ProPresenter on the screens."
      />
      <CheckListItem
        title="Test any Media needed for Worship"
        note="Load and play any videos or music from the iMac (unmute the iMac input on the mixer) and make sure you hear audio and see video on the screens."
      />
      <CheckListItem
        title="Sound Check Microphones"
        note="Test each of the four handheld mics by speaking into them while their inputs are unmuted (easier to do if two people are available)."
      />
      <h3>Worship Service Start (5 Minutes Before)</h3>
      <CheckListItem
        title="Start Live Stream"
        note="At 5 minutes before the hour, start the recording by pressing the red circle on the recorder screen.  At the same time, start the livestream broadcast by pressing the red button on the left front of the encoder.  Make sure the screen on the box says LIVE."
      />
      <CheckListItem
        title="Switch from Announcement Slides to Worship Slides"
        note="As soon as you see Sara sit down at the piano/organ, the slide operator should switch from the announcement slides to the first worship slide, which should say “Welcome to Clarkston United Methodist Church”.  From here on, the operator will manually advance the slides by paying close attention to what is going on in the service.  You can either use the space bar on the wireless keyboard, the right arrow or click the mouse to go to the next slide. (Using the mouse is discouraged, because it’s possible to click the wrong slide, unless it’s necessary to display slides out of order.)"
      />
      <CheckListItem
        title="Run Slides "
        note="Slide operators need to stay in sync with what is being read or sung when the congregation is either reading or singing. Advance to the next slide as soon as the second-to-last word at the bottom of a slide is reached.  This gives people a chance to see the next line before they need to read or sing it. Advance to the title slide of hymns as soon as the introduction music starts.  Leave it up so those using a hymnal can locate the hymn number during the intro.  Then advance to the first slide of lyrics as the introduction is ending, several seconds before the first line needs to be sung."
      />
      <CheckListItem
        title="Run Sound"
        note="Before the prelude starts, the sound operator should make sure the “C S L” [Chancel suspended left] mic is set to a 0 level on the “REC” output bus (with the blue “Mix” button illuminated in the “REC” master strip).  The faders for the three other suspended mics should all be down at the start of the service. When the blue “Mix” button is on in the “REC” strip, the faders to its left indicate at what level those sources are feeding the recording and the livestream, unless the source is muted (red “Mute” button is on). When the blue “Mix” button is on in the “L/R” strip on the far right, the faders to its left indicate the level each input sounds in the sanctuary speakers.  A good default starting level for any mics used in the service is the 0 level.  Generally, only un-mute (by turning the red “mute” button off) one mic at a time, corresponding to the person speaking.  Look ahead in the script/bulletin, so that you un-mute any mic before that person starts speaking. When more than one person will be speaking (during communion and baptisms, for example), you may un-mute more than one mic, but be cautious and listen for any feedback.  Reduce the fader level of any open mics when someone is not talking, if possible, and mute them when they are done speaking. The lecturn mic is the one most prone to feeding back.  Liturgists should be instructed to move the mic to point directly at their mouth when approaching the lecturn and to speak slowly and clearly with a strong voice.  People who speak softly or are not speaking directly into the mic will have a hard time being understood by the congregation.  If the mic is not pointing at their mouth and the sound operator has to increase the fader level so they can be heard, the chance of feedback is also increased."
      />
    </div>
  )
}
