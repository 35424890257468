import React, {Component} from "react"
import {connect} from "react-redux"
import {Redirect} from "react-router-dom"
import {loginUser} from "../actions"
import {withStyles} from "@material-ui/styles"

import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"

import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import Container from "@material-ui/core/Container"

const styles = () => ({
  "@global": {
    body: {
      backgroundColor: "#fff"
    }
  },
  paper: {
    marginTop: 100,
    display: "flex",
    padding: 20,
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "#f50057"
  },
  form: {
    marginTop: 1
  },
  errorText: {
    color: "#f50057",
    marginBottom: 5,
    textAlign: "center"
  }
  // submit: {
  //   backgroundColor: "#ade8e8",
  //   "&:hover": {backgroundColor: "#7fdbdb"}
  // }
})

class Login extends Component {
  state = {email: "", password: ""}

  handleEmailChange = ({target}) => {
    this.setState({email: target.value})
  }

  handlePasswordChange = ({target}) => {
    this.setState({password: target.value})
  }

  handleSubmit = e => {
    e.preventDefault()
    const {dispatch} = this.props
    const {email, password} = this.state

    dispatch(loginUser(email, password))
  }

  render() {
    const {classes, loginError, isAuthenticated} = this.props
    if (isAuthenticated) {
      return <Redirect to="/dash" />
    } else {
      return (
        <form onSubmit={this.handleSubmit}>
          <Container component="main" maxWidth="xs">
            <Paper className={classes.paper}>
              <p>CLARKSTON UMC STAFF PORTAL</p>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                onChange={this.handleEmailChange}
                autoComplete="off"
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                onChange={this.handlePasswordChange}
                autoComplete="off"
              />
              {loginError && (
                <Typography component="p" className={classes.errorText}>
                  Incorrect email or password.
                </Typography>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            </Paper>
          </Container>
        </form>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    isLoggingIn: state.auth.isLoggingIn,
    loginError: state.auth.loginError,
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default withStyles(styles)(connect(mapStateToProps)(Login))
