import React from "react"
import Drawer from "@material-ui/core/Drawer"
import {Link} from "react-router-dom"
import Button from "@material-ui/core/Button"
import {logoutUser} from "../actions"
import {connect} from "react-redux"

import {withStyles} from "@material-ui/styles"

const styles = () => ({
  sideDrawer: {
    width: "250px"
  },
  drawerLink: {
    width: "100%",
    justifyContent: "left",
    paddingLeft: "20px",
    paddingTop: "16px"
  }
})
class NavDrawer extends React.Component {
  handleLogout = () => {
    const {dispatch} = this.props
    dispatch(logoutUser())
  }

  render() {
    const {classes} = this.props
    return (
      <Drawer
        anchor="right"
        open={this.props.drawerOpened}
        onClose={this.props.toggleDrawer(false)}
      >
        <div
          className={classes.sideDrawer}
          onClick={this.props.toggleDrawer(false)}
          onKeyDown={this.props.toggleDrawer(false)}
        >
          <Button className={classes.drawerLink} component={Link} to="/dash">
            Dash
          </Button>
          <Button className={classes.drawerLink} component={Link} to="/arena">
            Arena Help
          </Button>
          <Button className={classes.drawerLink} component={Link} to="/sunday">
            Sunday Technology
          </Button>
          <Button
            color="secondary"
            className={classes.drawerLink}
            onClick={this.handleLogout}
          >
            Logout
          </Button>
        </div>
      </Drawer>
    )
  }
}

function mapStateToProps(state) {
  return {
    isLoggingOut: state.auth.isLoggingOut,
    logoutError: state.auth.logoutError,
    isAuthenticated: state.auth.isAuthenticated
  }
}

export default withStyles(styles)(connect(mapStateToProps)(NavDrawer))
