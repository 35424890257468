import React, {Component} from "react"

export default class dummy extends Component {
  render() {
    return (
      <div>
        <h1>this is a dummy page</h1>
        <p>this is a placeholder for the protected route</p>
      </div>
    )
  }
}
