import React from "react"
import {makeStyles} from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Divider from "@material-ui/core/Divider"

import Box from "@material-ui/core/Box"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  gridT: {
    marginTop: "10px",
    marginBottom: "10px"
  },
  gridTC: {
    fontWeight: 500
  },
  heading: {
    paddingRight: "4rem"
  },
  divider: {marginTop: "20px", marginBottom: "20px"},
  p: {
    paddingRight: "4rem",
    paddingBottom: "1rem",
    borderBottom: "2px solid #ade8e8"
  },
  right: {paddingLeft: "2rem"}
}))

const phoneData = [
  {
    company: "CCR",
    description: "Computer, WIFI and Phone Tech Support",
    phone: "248-284-7171"
  },
  {
    company: "IQ Life Safety Systems",
    description: "Fire and Intrusion Alarm Tech Support",
    phone: "248-328-0000"
  },
  {
    company: "Alarm Monitoring Company",
    description: "Fire and Intrusion Call Center",
    phone: "800-836-0142"
  },
  {
    company: "Emerald Gardens",
    description: "Snow Removal",
    phone: "248-521-5661"
  },
  {
    company: "J.J. Rolls",
    description: "HVAC Service",
    phone: "810-629-7752"
  },
  {
    company: "Advance Disposal",
    description: "Trash/Dumpster Service",
    phone: "248-625-5470"
  },
  {
    company: "Otis Elevator",
    description: "New Elevator Service",
    phone: "800-233-6847"
  },
  {
    company: "Kone Elevator",
    description: "Old Elevator Service",
    phone: "877-276-8691"
  }
]

const PhoneCard = () => {
  const classes = useStyles()
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item sm={12} md={4}>
        <div className={classes.right}>
          <h2 className={classes.heading}>Phone Numbers</h2>
          <p className={classes.p}>Quick reference to common numbers.</p>
        </div>
      </Grid>
      <Grid item sm={12} md={8}>
        <Card>
          <CardContent>
            <Box>
              {phoneData.map(({company, description, phone}) => (
                <React.Fragment key={company}>
                  <Grid container spacing={2}>
                    <Grid className={classes.gridTC} item xs={12} md={4}>
                      {company}
                    </Grid>
                    <Grid item xs={12} md={5}>
                      {description}
                    </Grid>
                    <Grid item xs={12} md={3}>
                      {phone}
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider} />
                </React.Fragment>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default PhoneCard
