import React, {Component} from "react"
import Container from "@material-ui/core/Container"
import CheckList from "./CheckList"

export default class Sunday extends Component {
  render() {
    return (
      <div>
        <Container>
          <h1>Sunday Technology Checklist</h1>
          <CheckList />
        </Container>
      </div>
    )
  }
}
