import firebase from "firebase/app"
import "firebase/auth"

const config = {
  apiKey: "AIzaSyCaMIGIbTQsj5oz0GFu_lVOWiovPWy8mjw",
  authDomain: "trauth-2a39f.firebaseapp.com",
  databaseURL: "https://trauth-2a39f.firebaseio.com",
  projectId: "trauth-2a39f",
  storageBucket: "trauth-2a39f.appspot.com",
  messagingSenderId: "267295646766",
  appId: "1:267295646766:web:61618e3e38f55437b26bbf"
}

const myFirebase = firebase.initializeApp(config)
myFirebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

export {myFirebase}
