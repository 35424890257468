import React from "react"

import {Route, Switch} from "react-router-dom"
import {connect} from "react-redux"

import {createMuiTheme} from "@material-ui/core/styles"
import {ThemeProvider} from "@material-ui/styles"

import ProtectedRoute from "./ProtectedRoute"
import Home from "./Home"
import Dash from "./Dash"
import Arena from "./Arena"
import NavBar from "./NavBar.js"
import CssBaseline from "@material-ui/core/CssBaseline"
import Dummy from "./dummy"
import Sunday from "./Sunday"

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#7fdbdb"
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#11cb5f"
    }
  }
})

function App(props) {
  const {isAuthenticated, isVerifying} = props
  return (
    <div>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <NavBar />
        <Switch>
          <ProtectedRoute
            path="/dummy"
            component={Dummy}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            path="/dash"
            component={Dash}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            path="/arena"
            component={Arena}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <ProtectedRoute
            path="/sunday"
            component={Sunday}
            isAuthenticated={isAuthenticated}
            isVerifying={isVerifying}
          />
          <Route path="/" exact component={Home} />
        </Switch>
      </ThemeProvider>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isVerifying: state.auth.isVerifying
  }
}

export default connect(mapStateToProps)(App)
